<template>
  <ApolloQuery :tag="'main'" :query="PRODUCT_QUERY" :variables="variables">
    <template v-slot="{ result: { loading, error, data } }">
      <TalpaLoaderWrapper :transparent="true" v-if="loading" />
      <ErrorAtom v-else-if="error" :error="error" />
      <ProductDetailsMolecule v-else-if="data" :product="data.product" />
    </template>
  </ApolloQuery>
</template>

<script>
import { TalpaLoaderWrapper } from '@common/components'

import PRODUCT_QUERY from '#/graphql/marketplace/productQuery.gql'
import ErrorAtom from '@/components/Atomic/Atoms/ErrorAtom.vue'
import ProductDetailsMolecule from './ProductDetailsMolecule'

export default {
  inject: ['uiSettings'],
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  components: {
    TalpaLoaderWrapper,
    ErrorAtom,
    ProductDetailsMolecule,
  },
  data() {
    return {
      PRODUCT_QUERY,
    }
  },
  computed: {
    variables() {
      const where = {
        id: this.productId,
      }
      const language = this.uiSettings?.language ?? 'EN'
      return { where, language }
    },
  },
}
</script>
