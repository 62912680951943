<template>
  <TierLevelAtom>
    <button class="toggle" @click="selectTierLevel">
      <ChevronRightIcon v-if="!isExpanded" />
      <ChevronDownIcon v-else />
      <div class="span">Tier Level {{ tierLevel.tierLevel }}</div>
    </button>
    <div class="details">
      <transition name="fade-in-down">
        <div v-if="isExpanded">
          <div class="description">
            <VueMarkdown v-if="tierLevel.description" class="markdown" :source="tierLevel.description" />
          </div>
          <div class="bundles" v-if="tierLevel.bundles.length > 0">
            <strong>Bundles:</strong>
            <router-link
              v-for="bundledTierLevel in tierLevel.bundles"
              :key="bundledTierLevel.id"
              :to="{
                name: 'ProductDetails',
                params: {
                  id: bundledTierLevel.product.id,
                },
                query: {
                  tier: bundledTierLevel.tierLevel,
                },
              }"
            >
              {{ bundledTierLevel?.product.name }} - Tier Level {{ bundledTierLevel.tierLevel }}
            </router-link>
          </div>
          <div class="bundled-by" v-if="tierLevel.bundledBy.length > 0">
            <strong>Bundled by:</strong>
            <router-link
              v-for="bundledByTierLevel in tierLevel.bundledBy"
              :key="bundledByTierLevel.id"
              :to="{
                name: 'ProductDetails',
                params: {
                  id: bundledByTierLevel.product.id,
                },
                query: {
                  tier: bundledByTierLevel.tierLevel,
                },
              }"
            >
              {{ bundledByTierLevel.product.name }} - Tier Level {{ bundledByTierLevel.tierLevel }}
            </router-link>
          </div>
        </div>
      </transition>
    </div>
  </TierLevelAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ChevronRightIcon, ChevronDownIcon } from 'vue-feather-icons'
import chroma from 'chroma-js'
import VueMarkdown from 'vue-markdown'

import { buttonReset, flexCenter } from '@styles/mixins'

const TierLevelAtom = styled('div')`
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: 1fr;
  border-bottom: 2px solid ${({ theme }) => theme.colors.mainBG};
  &:last-child {
    border: none;
  }

  > button {
    ${buttonReset}
    ${flexCenter}
    justify-content: flex-start;
    color: ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.solidBG};
    font-size: 1.15rem;
    padding: 0.5rem 1rem;
  }

  > .details {
    overflow: hidden;
    background: ${({ theme }) => chroma(theme.colors.solidBG).alpha(0.7).css()};
    > div {
      padding: 1rem 2rem 2rem 2rem;
    }
    .bundles,
    .bundled-by {
      display: grid;
      grid-template-column: 1fr;
      grid-auto-rows: min-content;
      grid-gap: 0.25rem;
    }
  }
`

export default {
  props: {
    tierLevel: {
      type: Object,
      required: true,
    },
  },
  components: {
    TierLevelAtom,
    ChevronRightIcon,
    ChevronDownIcon,
    VueMarkdown,
  },
  computed: {
    isExpanded() {
      return parseInt(this.$route?.query?.tier) === this.tierLevel.tierLevel
    },
  },
  methods: {
    selectTierLevel() {
      if (parseInt(this.$route?.query?.tier) === this.tierLevel.tierLevel) {
        this.$router.push({
          query: {
            ...this.$route?.query,
            tier: undefined,
          },
        })
      } else {
        this.$router.push({
          query: {
            ...this.$route?.query,
            tier: this.tierLevel.tierLevel,
          },
        })
      }
    },
  },
}
</script>
