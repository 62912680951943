var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TierLevelsMolecule', _vm._l(_vm.tierLevels, function (tierLevel) {
    return _c('TierLevelAtom', {
      key: tierLevel.id,
      attrs: {
        "tierLevel": tierLevel
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }