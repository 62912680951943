<template>
  <ProductDetailsMolecule>
    <section class="overview">
      <ProductIconMolecule :upload="upload" />
      <div class="name">
        <router-link
          :to="{
            name: 'ProductDetails',
            params: { id: product.id },
          }"
        >
          {{ product.name }}
        </router-link>
      </div>
      <ProductActionsAtom class="actions" :product="product" />
      <div class="description">
        <VueMarkdown v-if="product.description" class="markdown" :source="product.description" />
      </div>
    </section>
    <TierLevelsMolecule class="tier-levels" :tierLevels="product.tierLevels" />
  </ProductDetailsMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import VueMarkdown from 'vue-markdown'

import ProductIconMolecule from './ProductIconMolecule'
import ProductActionsAtom from './ProductActionsAtom'
import TierLevelsMolecule from './TierLevelsMolecule'

const ProductDetailsMolecule = styled('div')`
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  overflow: hidden;
  section.overview {
    padding: 1rem;
    background: ${({ theme }) => chroma(theme.colors.solidBG).alpha(0.7).css()};
    display: grid;
    grid-template-columns: 4rem 1fr min-content;
    grid-template-rows: min-content min-content;
    grid-gap: 1rem;
    grid-template-areas:
      'icon name actions'
      'icon short-description short-description';
    overflow: hidden;

    > .icon {
      grid-area: icon;
      justify-self: center;
    }

    > .actions {
      grid-area: actions;
      align-self: center;
      height: 3rem;
    }

    > .name {
      grid-area: name;
      font-size: 1.1rem;
      font-weight: bold;
      align-self: center;
    }

    > .description {
      grid-area: short-description;
      > .markdown {
        > p:first-child {
          margin-top: 0;
        }
        > p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  > .tier-levels {
    display: grid;
  }
`

export default {
  inject: ['theme'],
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    VueMarkdown,
    ProductDetailsMolecule,
    ProductIconMolecule,
    ProductActionsAtom,
    TierLevelsMolecule,
  },
  computed: {
    upload() {
      if (this.product?.hasDarkIconOnly) {
        return this.product.iconDark
      }
      return this.theme.isDark ? this.product.iconDark : this.product.iconLight
    },
  },
}
</script>
