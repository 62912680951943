var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TierLevelAtom', [_c('button', {
    staticClass: "toggle",
    on: {
      "click": _vm.selectTierLevel
    }
  }, [!_vm.isExpanded ? _c('ChevronRightIcon') : _c('ChevronDownIcon'), _c('div', {
    staticClass: "span"
  }, [_vm._v("Tier Level " + _vm._s(_vm.tierLevel.tierLevel))])], 1), _c('div', {
    staticClass: "details"
  }, [_c('transition', {
    attrs: {
      "name": "fade-in-down"
    }
  }, [_vm.isExpanded ? _c('div', [_c('div', {
    staticClass: "description"
  }, [_vm.tierLevel.description ? _c('VueMarkdown', {
    staticClass: "markdown",
    attrs: {
      "source": _vm.tierLevel.description
    }
  }) : _vm._e()], 1), _vm.tierLevel.bundles.length > 0 ? _c('div', {
    staticClass: "bundles"
  }, [_c('strong', [_vm._v("Bundles:")]), _vm._l(_vm.tierLevel.bundles, function (bundledTierLevel) {
    return _c('router-link', {
      key: bundledTierLevel.id,
      attrs: {
        "to": {
          name: 'ProductDetails',
          params: {
            id: bundledTierLevel.product.id
          },
          query: {
            tier: bundledTierLevel.tierLevel
          }
        }
      }
    }, [_vm._v(" " + _vm._s(bundledTierLevel === null || bundledTierLevel === void 0 ? void 0 : bundledTierLevel.product.name) + " - Tier Level " + _vm._s(bundledTierLevel.tierLevel) + " ")]);
  })], 2) : _vm._e(), _vm.tierLevel.bundledBy.length > 0 ? _c('div', {
    staticClass: "bundled-by"
  }, [_c('strong', [_vm._v("Bundled by:")]), _vm._l(_vm.tierLevel.bundledBy, function (bundledByTierLevel) {
    return _c('router-link', {
      key: bundledByTierLevel.id,
      attrs: {
        "to": {
          name: 'ProductDetails',
          params: {
            id: bundledByTierLevel.product.id
          },
          query: {
            tier: bundledByTierLevel.tierLevel
          }
        }
      }
    }, [_vm._v(" " + _vm._s(bundledByTierLevel.product.name) + " - Tier Level " + _vm._s(bundledByTierLevel.tierLevel) + " ")]);
  })], 2) : _vm._e()]) : _vm._e()])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }