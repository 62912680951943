<template>
  <TierLevelsMolecule>
    <TierLevelAtom v-for="tierLevel in tierLevels" :key="tierLevel.id" :tierLevel="tierLevel" />
  </TierLevelsMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import TierLevelAtom from './TierLevelAtom'

const TierLevelsMolecule = styled('div')``

export default {
  props: {
    tierLevels: {
      type: Array,
      required: true,
    },
  },
  components: {
    TierLevelsMolecule,
    TierLevelAtom,
  },
}
</script>
