var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ProductDetailsMolecule', [_c('section', {
    staticClass: "overview"
  }, [_c('ProductIconMolecule', {
    attrs: {
      "upload": _vm.upload
    }
  }), _c('div', {
    staticClass: "name"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'ProductDetails',
        params: {
          id: _vm.product.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.product.name) + " ")])], 1), _c('ProductActionsAtom', {
    staticClass: "actions",
    attrs: {
      "product": _vm.product
    }
  }), _c('div', {
    staticClass: "description"
  }, [_vm.product.description ? _c('VueMarkdown', {
    staticClass: "markdown",
    attrs: {
      "source": _vm.product.description
    }
  }) : _vm._e()], 1)], 1), _c('TierLevelsMolecule', {
    staticClass: "tier-levels",
    attrs: {
      "tierLevels": _vm.product.tierLevels
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }