<template>
  <ProductDetailsOrganism :productId="$route?.params?.id" />
</template>

<script>
import ProductDetailsOrganism from './ProductDetailsOrganism'

export default {
  props: {
    search: {
      type: String,
      required: true,
    },
  },
  components: {
    ProductDetailsOrganism,
  },
  computed: {
    selectedProductType() {
      return this.$route?.query?.type ?? 'all'
    },
    productTypes() {
      return ['all', 'app', 'bundle'].map(productType => {
        return {
          id: productType,
          label: this.$t(`marketplace.productTypes.${productType}`),
          to: {
            name: 'ProductCatalog',
            query: {
              ...this.$route.query,
              type: productType === 'all' ? undefined : productType,
            },
          },
          isForcedActive: productType === 'all' && this.$route?.query?.type === undefined,
        }
      })
    },
  },
}
</script>
