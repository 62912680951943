var render = function () {
  var _vm$$route, _vm$$route$params;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ProductDetailsOrganism', {
    attrs: {
      "productId": (_vm$$route = _vm.$route) === null || _vm$$route === void 0 ? void 0 : (_vm$$route$params = _vm$$route.params) === null || _vm$$route$params === void 0 ? void 0 : _vm$$route$params.id
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }